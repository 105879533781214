import { AspectRatio } from '@microfrontends/react-components'
import { TBanner } from '../../services/type'
import { BannerCompanySection, Company, SlideSection } from './styles'
import Image from 'next/legacy/image'
import { memo } from 'react'
import { BLUR_DEFAULT_IMAGE } from '../constants'

const COMPANY_QUOTE_REGEX = RegExp('#([^#]+)#', 'g')

interface IProps {
	banner: TBanner
	t: (key: string) => string
	priority?: boolean
}

function BannerItem({ banner, t, priority = false }: IProps) {
	const backgroundImage = banner?.bannerImage ? banner.bannerImage : ''
	const companyLogo = banner?.companyLogo ? banner.companyLogo : ''
	const companyLinkText = banner.text
		.match(COMPANY_QUOTE_REGEX)?.[0]
		.replace(/#/g, '')
	const comapnyQuoteText = banner.text.replace(COMPANY_QUOTE_REGEX, '')
	return (
		<SlideSection key={banner.id}>
			<a
				href={banner.url}
				target='_blank'
				tabIndex={1}
				aria-label={`Hero Banner- ${comapnyQuoteText}`}
				style={{ position: 'relative', zIndex: 2 }}
			>
				<AspectRatio ratio={2560 / 900}>
					{backgroundImage !== '' ? (
						<Image
							style={{
								zIndex: 1,
								borderRadius: '12px 12px 0 0 '
							}}
							src={backgroundImage}
							placeholder='blur'
							blurDataURL={BLUR_DEFAULT_IMAGE}
							layout='fill'
							priority={priority}
							fetchPriority={priority ? 'high' : undefined}
							alt={banner?.companyQuote || ''}
						/>
					) : null}
				</AspectRatio>
			</a>
			<BannerCompanySection
				href={banner.url}
				target='_blank'
				aria-label={`Company logo - ${comapnyQuoteText}`}
			>
				<Company.Container>
					{companyLogo ? (
						<Image
							src={companyLogo}
							alt={banner.text}
							width={72}
							height={72}
							placeholder='blur'
							blurDataURL={BLUR_DEFAULT_IMAGE}
							priority={priority}
							fetchPriority={priority ? 'high' : undefined}
							loading={!priority ? 'lazy' : undefined}
							style={{
								borderRadius: 6,
								objectFit: 'contain'
							}}
						/>
					) : null}
					<div>
						{comapnyQuoteText !== '' ? (
							<Company.Quote>{comapnyQuoteText}</Company.Quote>
						) : (
							<Company.Quote>{banner.companyName}</Company.Quote>
						)}
						{comapnyQuoteText !== '' ? (
							<Company.Name>{banner.companyName}</Company.Name>
						) : (
							<Company.Name></Company.Name>
						)}
					</div>
				</Company.Container>
				<Company.Link>
					{companyLinkText ? companyLinkText : t('Discover Now')}
				</Company.Link>
			</BannerCompanySection>
		</SlideSection>
	)
}

export default memo(BannerItem)
